<template>
  <div class="pa24 view">
    员工姓名:{{ name }}
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column
          type="index"
          align="center"
          label="序号"
          show-overflow-tooltip
        />
        <el-table-column prop="title" align="center" label="文章标题" />
        <el-table-column prop="pubTime" align="center" label="发布时间" />
        <el-table-column prop="shareNum" align="center" label="分享次数" />
        <el-table-column prop="winCustomers" align="center" label="获客数" />
        <el-table-column prop="seeNum" align="center" label="浏览次数" />
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { queryStaffShareDetail } from "@/api/articleManagement";
export default {
  data() {
    return {
      name: "",
      userId: "",
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
    };
  },
  components: {
    commonTable,
  },
  created() {
    const { name, userId } = this.$route.query;
    this.name = name;
    this.userId = userId;
    this.queryStaffShareDetail();
  },
  methods: {
    handleSizeChange(val) {
      this.currentPage =1;
      this.pageSize = val;
      this.queryStaffShareDetail();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryStaffShareDetail();
    },
    async queryStaffShareDetail() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        userId: this.userId,
      };
      try {
        this.loading = true;
        const result = await queryStaffShareDetail(data);
        this.loading = false;
        //-console.log(result);
        
        //-console.log(result);
        const { total, list } = result?.data;
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.loading = false;
        //-console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>